import school from '../../content/images/school.png'
export default [
  {
    title: 'College - Bina Nusantara University (2018 - now)',
    path: 'https://binus.ac.id',
    img: school,
  },
  {
    title: 'College - PPTI BCA 6 (2018 - 2021)',
    path: 'https://bca.co.id/ppa-ppti',
    img: school,
  },
  {
    title: 'High School - SMAK 1 BPK PENABUR Jakarta (2015 - 2018)',
    path: 'https://smak1.bpkpenaburjakarta.or.id/',
    img: school,
  },
  {
    title: 'Junior High School - SMPK IPEKA Tomang 2 (2012 - 2015)',
    path: 'https://ipeka.org/',
    img: school,
  },
  {
    title: 'Elementary School - SDK IPEKA Tomang 1 (2006 - 2012)',
    path: 'https://ipeka.org/',
    img: school,
  },
  
]
