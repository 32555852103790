import trophy from '../../content/images/trophy.png'
export default [
  {
    title: 'National Logic Competition - CITE UP (First Place)',
    path: '',
    img: trophy,
  },
  {
    title: 'National Physics and Computer Competition - Electra (First Place)',
    path: '',
    img: trophy,
  },
  {
    title: 'National Programming and Logic Competition - PROLOG (First Place)',
    path: '',
    img: trophy,
  },
  {
    title: 'National Physics Competition - PRF UI (First Place)',
    path: '',
    img: trophy,
  },
  {
    title: 'National Logic Competition - ITS (First Place)',
    path: '',
    img: trophy,
  },
  {
    title: 'National Physics Competition - UNY (First Place)',
    path: '',
    img: trophy,
  },

  
]
