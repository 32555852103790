import school from '../../content/images/school.png'
import work from '../../content/images/work.svg'
import book from '../../content/images/book.svg'
export default [
  {
    title: 'PT. Bank Central Asia - Cyber Security (2021 - now)',
    path: 'https://bca.co.id',
    img: work,
  },
  {
    title: 'Course-Net - IT Trainer (CEH and CHFI) (2021 - now)',
    path: 'https://www.course-net.com',
    img: book,
  },
  {
    title: 'PT. Bank Central Asia - IT Specialist [internship] (2020 - 2021)',
    path: 'https://bca.co.id/',
    img: work,
  },
  {
    title: 'Wardaya College - Computer Olympiad Teacher (2018 - now)',
    path: 'https://www.wardayacollege.com',
    img: school,
  },
  
]
