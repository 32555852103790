import microsoft from '../../content/images/microsoft.svg'
import eccouncil from '../../content/images/eccouncil.png'
import coursera from '../../content/images/coursera.jpg'
import qwiklabs from '../../content/images/qwiklabs.png'
import icsi from '../../content/images/icsi.png'

export default [
  {
    title: 'Certified Ethical Hacker (C|EH)',
    path: 'https://aspen.eccouncil.org/VerifyBadge?type=certification&a=DYxz6RaoUlbU0zRGhNOhKDBqlAmi6sk7zd0HuR2Cwjg=',
    img: eccouncil,
  },
  {
    title: 'Computer Hacking Forensic Investigator (CHFI)',
    path: 'https://aspen.eccouncil.org/VerifyBadge?type=certification&a=2jEor3aEs1aioqtEUnyEK5a2UPwrTtOUhPp5QSiLOmA=',
    img: eccouncil,
  },
  {
    title: 'Exam 483: Programming in C#',
    path: 'https://www.youracclaim.com/badges/f961a793-49cf-4283-9b32-784a07dd5c97/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'MCSA: BI Reporting - Certified 2019',
    path: 'https://www.youracclaim.com/badges/6859ccbc-267a-4d9f-968b-9ca6e939bdf1/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'Exam 779: Analyzing and Visualizing Data with Microsoft Excel',
    path: 'https://www.youracclaim.com/badges/72fb4a59-9f80-40f9-856b-75a51858f21f/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'MCSE: Data Management and Analytics — Certified 2020',
    path: 'https://www.youracclaim.com/badges/9d0017f8-d994-464b-a1fb-4c219f2af3ca/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'Exam 762: Developing SQL Databases',
    path: 'https://www.youracclaim.com/badges/a6218f28-cbb3-4126-be0b-b6de5f72cd00/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'MCSA: Universal Windows Platform - Certified 2020',
    path: 'https://www.youracclaim.com/badges/3b44cb74-58e6-424d-bf97-b3a8952de21c/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'Exam 357: Developing Mobile Apps',
    path: 'https://www.youracclaim.com/badges/065e6260-d3cd-48f6-a5ff-9a699c23a362/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'End-to-End Machine Learning with TensorFlow on GCP',
    path: 'https://www-origin.coursera.org/account/accomplishments/certificate/XWD5ZN4VU5SX',
    img: coursera,
  },
  {
    title: 'ICSI | CNSS Certified Network Security Specialist',
    path: 'https://www.credential.net/41d90331-4b90-4e39-9524-e30b8aef2bc0',
    img: icsi,
  },
  {
    title: 'Data Science on the Google Cloud Platform',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Data Engineering',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Baseline: Data, ML, AI',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Websites and Web Applications',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Intro to ML: Language Processing',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Intro to ML: Image Processing',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Intermediate ML: TensorFlow on GCP',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Google Developer Essentials',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Google Cloud Solutions II: Data and Machine Learning',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Google Cloud Run Serverless Workshop',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Deploying Applications',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Data Science on Google Cloud: Machine',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Cloud Logging',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Cloud Development',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Application Development - Python',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  },
  {
    title: 'Application Development - Java',
    path: 'https://www.qwiklabs.com/public_profiles/29c5c7db-c9e1-47e3-98d9-248c0bf3f671',
    img: qwiklabs,
  }
]
